import React from "react"
import { Link } from 'react-scroll'

import {
  Container,
} from "reactstrap";

export default function DemoFooter() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <Link
            className="footer-brand"
            to="home"
          >
            <img
              alt="MycoHealing.Farm"
              className="footer-logo"
              style={{ cursor: "pointer" }}
              src={ require("assets/svg/logo.svg").default }
            />
          </Link>
          <ul className="pull-right footer-links">
            <li>
              <Link
                to="shop"
                style={{ cursor: "pointer" }}
                className="ml-1 nav-link"
              >
                Магазин
              </Link>
            </li>
            <li>
              <Link
                to="about"
                style={{ cursor: "pointer" }}
                className="ml-1 nav-link"
              >
                О Нас
              </Link>
            </li>
            <li>
              <Link
                to="contacts"
                style={{ cursor: "pointer" }}
                className="ml-1 nav-link"
              >
                Контакты
              </Link>
            </li>
          </ul>
        </Container>
      </footer>
    </>
  );
}
