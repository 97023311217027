import React from "react";
import { Link } from 'react-scroll'

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap';

import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

export default function Ecommerce() {
  const [transform, setTransform] = React.useState(
    "translate3d(0," +
      (window.innerWidth >= 768 ? window.pageYOffset / 3 : 0) +
      "px,0)"
  );
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("ecommerce-page");
    if (window.innerWidth >= 768) {
      const windowScrollTop = window.pageYOffset / 3;
      setTransform("translate3d(0," + windowScrollTop + "px,0)");
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      document.body.classList.remove("ecommerce-page");
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  }, []);
  const resetTransform = () => {
    const windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };

  return (
    <>
      <div id="home" />
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="header header-1">
          <div className="page-header header-filter">
            <div
              className="page-header-image"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/shop.jpg").default + ")",
                transform: transform,
              }}
            />
            <Container>
              <Row>
                <Col className="ml-auto text-right" lg="7" md="7">
                  <div className="buttons" style={{ marginTop: '50%'}} >
                    <img
                        alt="MycoHealing.Farm"
                        style={{ width: '60%', verticalAlign: 'bottom !important', marginBottom: 40 }}
                        src={
                          require("assets/svg/logo-title.svg").default
                        }
                    />
                    <br />

                    <Link to="shop">
                      <Button
                        className="btn-darker mr-3 pulse"
                        color="warning"
                        href="#shop"
                        onClick={(e) => e.preventDefault()}
                        size="lg"
                      >
                        <i className="tim-icons icon-basket-simple" />
                      </Button>
                    </Link>

                    <Link to="shop">
                      <p style={{ cursor: "pointer" }}>Купить!</p>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div id="shop" className="main">
          <div className="section">
            <Container>
              <Row>
                <Col className="mx-auto text-center" md="6">
                  <h3 className="desc my-5">Магазин</h3>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Row>

                    <Col lg="6" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <Link to="contacts">
                            <img
                              alt="Кордицепс"
                              style={{ cursor: "pointer" }}
                              src={require("assets/img/01_candy.jpg").default}
                            />
                          </Link>
                        </div>
                        <CardBody>
                          <Link to="contacts">
                            <CardTitle style={{ cursor: "pointer" }} tag="h4">Конфеты из ежовика гребенчатого</CardTitle>
                            <p style={{ cursor: "pointer" }}>Низкокалорийны и без добавления сахара, что делает их идеальным выбором для людей, следящих за своим питанием.</p>
                          </Link>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price-new ml-2">850 ₽ (одна пачка)</span><br />
                              <span className="price-new ml-2">600 ₽ (от 10 пачек)</span>
                            </div>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="6" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <Link to="contacts">
                            <img
                              alt="Ежовик Гребенчатый"
                              style={{ cursor: "pointer" }}
                              src={require("assets/img/02_ezhovik.jpg").default}
                            />
                          </Link>
                        </div>
                        <CardBody>
                          <Link to="contacts">
                            <CardTitle style={{ cursor: "pointer" }} tag="h4">Ежовик Гребенчатый</CardTitle>
                            <p style={{ cursor: "pointer" }}>Деликатесный и ноотропный гриб содержащий около 20-ти ароматических компонент и содержит в себе множество полезных элементов</p>
                          </Link>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price-new ml-2">1000 ₽ (100 гр)</span><br />
                              <span className="price-new ml-2">2500 р (300 гр на месяц)</span><br />
                              <span className="price-new ml-2">3500 р (500 гр)</span><br />
                              <span className="price-new ml-2">4000 р (1 кг)</span>
                            </div>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>

                    {/*<Col lg="3" md="6">*/}
                    {/*  <Card className="card-product card-plain">*/}
                    {/*    <div className="card-image">*/}
                    {/*      <Link to="contacts">*/}
                    {/*        <img*/}
                    {/*          alt="Рейши"*/}
                    {/*          style={{ cursor: "pointer" }}*/}
                    {/*          src={require("assets/img/reishi.jpg").default}*/}
                    {/*        />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*    <CardBody>*/}
                    {/*      <Link to="contacts">*/}
                    {/*        <CardTitle style={{ cursor: "pointer" }} tag="h4">Рейши</CardTitle>*/}
                    {/*        <p style={{ cursor: "pointer" }}>Ganoderma Lucidum - зерновой мицелий 2 литра</p>*/}
                    {/*      </Link>*/}
                    {/*      <CardFooter>*/}
                    {/*        <div className="price-container">*/}
                    {/*          <span className="price-new ml-2">2000 ₽</span>*/}
                    {/*        </div>*/}
                    {/*      </CardFooter>*/}
                    {/*    </CardBody>*/}
                    {/*  </Card>*/}
                    {/*</Col>*/}

                    {/*<Col lg="3" md="6">*/}
                    {/*  <Card className="card-product card-plain">*/}
                    {/*    <div className="card-image">*/}
                    {/*      <Link to="contacts">*/}
                    {/*        <img*/}
                    {/*          alt="Рейши"*/}
                    {/*          style={{ cursor: "pointer" }}*/}
                    {/*          src={require("assets/img/reishi2.jpg").default}*/}
                    {/*        />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*    <CardBody>*/}
                    {/*      <Link to="contacts">*/}
                    {/*        <CardTitle style={{ cursor: "pointer" }} tag="h4">Рейши</CardTitle>*/}
                    {/*        <p style={{ cursor: "pointer" }}>Ganoderma Lucidum - готовый к плодоношению 2 литра</p>*/}
                    {/*      </Link>*/}
                    {/*      <CardFooter>*/}
                    {/*        <div className="price-container">*/}
                    {/*          <span className="price-new ml-2">2000 ₽</span>*/}
                    {/*        </div>*/}
                    {/*      </CardFooter>*/}
                    {/*    </CardBody>*/}
                    {/*  </Card>*/}
                    {/*</Col>*/}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          <div id="about" className="features-6">
            <Container>
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="info info-horizontal info-default">
                    <div className="icon icon-warning">
                      <i className="tim-icons icon-atom" />
                    </div>
                    <div className="description">
                      <h3 className="info-title">Кто Мы?</h3>
                      <p>
                        Мы активно развивающаяся грибная ферма, специализирующаяся на выращивании полезных грибов.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal info-default">
                    <div className="icon icon-info">
                      <i className="tim-icons icon-app" />
                    </div>
                    <div className="description">
                      <h3 className="info-title">Сертификат качества</h3>
                      <p>
                        Качество подтверждено сертификатом.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal info-default">
                    <div className="icon icon-success">
                      <i className="tim-icons icon-bell-55" />
                    </div>
                    <div className="description">
                      <h3 className="info-title">Доставка</h3>
                      <p>
                        Доставка возможна почтой, транспортными компаниями или курьером.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg="6" xs="10">
                  <img
                    alt="..."
                    className="shape"
                    src={require("assets/img/path2.png").default}
                  />
                  <figure className="ie-non-standard-hero-shape">
                    <svg
                      className="injected-svg js-svg-injector"
                      style={{ enableBackground: "new 10 12 878.9 907" }}
                      viewBox="10 12 878.9 907"
                      x="0px"
                      y="0px"
                      xmlSpace="preserve"
                    >
                      <g>
                        <defs>
                          <path
                            d="M329.15,403.44c30.22-62,26.51-223.94,94.06-268.46C479,98.23,560.16,257,700.68,151.61c71.25-53.44,85.54,81,160.38,172.6C1008.5,504.74,881.5,639.14,825.35,686.6c-62.54,52.85-246.14,24.42-386.7,79.28S214.07,834,202.07,702C190.39,573.57,288.69,486.43,329.15,403.44Z"
                            id="firstShape"
                          />
                        </defs>
                        <clipPath id="secondShape">
                          <use
                            style={{ overflow: "visible" }}
                            xlinkHref="#firstShape"
                          />
                        </clipPath>
                        <g clipPath="url(#secondShape)">
                          <image
                            height="900"
                            id="imageShape1"
                            style={{ overflow: "visible" }}
                            transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                            width="900"
                            xlinkHref={require("assets/img/max.jpg").default}
                          />
                        </g>
                      </g>
                      <g>
                        <defs>
                          <path
                            d="M337.17,855.62c-7.81-35.46,42.38-43.95,63.66-52.44,24.39-9.74,135.86-48,192.58-52.52,64.22-5.13,190.21-26.84,160.46,35.34-19.76,41.3-51.47,64.52-87.63,62.33-46.36-2.81-101.56,4.39-150.8,44C448.53,946.08,450.93,865,412,868,372.28,871,340.79,872.08,337.17,855.62Z"
                            id="thirdShape"
                          />
                        </defs>
                        <clipPath id="fourthShape">
                          <use
                            style={{ overflow: "visible" }}
                            xlinkHref="#thirdShape"
                          />
                        </clipPath>
                        <g
                          clipPath="url(#fourthShape)"
                          transform="matrix(1 0 0 1 0 0)"
                        >
                          <image
                            height="1000"
                            id="imageShape2"
                            style={{ overflow: "visible" }}
                            transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                            width="900"
                            xlinkHref={require("assets/img/max.jpg").default}
                          />
                        </g>
                      </g>
                    </svg>
                  </figure>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="cd-section" id="contacts">
            <div
              className="contactus-1 section-image section-image--contact-us"
              style={{ backgroundImage: "url(" + require("assets/img/contact-us.jpeg").default + ")" }}
            >
              <Container>
                <Row>
                  <Col md="5">
                    <h2 className="title">Контакты</h2>
                    <p className="description">
                      Вам нужна дополнительная информация?
                      Свяжитесь с нами. Мы будем очень рады ответить на Ваши вопросы!
                    </p>
                    <div className="info info-horizontal">
                      <div className="icon icon-primary">
                        <i className="tim-icons icon-email-85" />
                      </div>
                      <div className="description">
                        <h4 className="info-title">Напишмие нам письмо</h4>
                        <p className="description">
                          <a href="mailto:info@mycohealing.farm">info@mycohealing.farm</a><br />
                        </p>
                      </div>
                    </div>
                    <div className="info info-horizontal">
                      <div className="icon icon-primary">
                        <i className="tim-icons icon-mobile" />
                      </div>
                      <div className="description">
                        <h4 className="info-title">Или пишите в телеграмм</h4>
                        <p className="description">
                          <a href="https://telegram.me/SergeiSpiraliom">@SergeiSpiraliom</a><br />
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div style={{ textAlign: "right", marginTop: "55px", marginBottom: "-60px" }}>
                      <a style={{ fontSize: "11px" }} className="nunito" href='https://www.freepik.com/photos/food'>Food photo created by wirestock - www.freepik.com</a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <DemoFooter />
      </div>
    </>
  );
}
