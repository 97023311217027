import React from "react";
import { Link } from 'react-scroll'

import {
  UncontrolledCollapse,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavItem,
} from "reactstrap";

export default function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };
  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container>
          <div className="navbar-translate">
            <Link
              to="home"
              style={{ cursor: "pointer" }}
              className="navbar-brand"
            >
              <img
                alt="MycoHealing.Farm"
                className="myco-logo"
                src={
                  require("assets/svg/logo.svg").default
                }
              />
              <span>MycoHealing.farm</span>
            </Link>
            <button className="navbar-toggler" id="navigation">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <UncontrolledCollapse navbar toggler="#navigation">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link style={{ cursor: "pointer" }} to="home">
                    Myco<span>Healing.Farm</span>
                  </Link>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" id="navigation">
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  to="shop"
                >
                  Магазин
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  to="about"
                >
                  О Нас
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  to="contacts"
                >
                  Контакты
                </Link>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}
