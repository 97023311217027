import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.2.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import Ecommerce from "views/examples/Ecommerce.js";
import Error404 from "views/examples/Error404.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={(props) => <Ecommerce {...props} />} />
      <Route path="/404" render={(props) => <Error404 {...props} />} />
      <Redirect to="/404" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
